<template>
  <div class="card--order">
    <div class="left--side">
      <div class="status--mobile">
        <div class="label--container">
          <div
            class="label"
            :class="{
              'label--ongoing': order.status.type === 'ON_GOING',
              'label--canceled':
                order.status.type === 'CANCELED' || order.status.type === 'EXPIRED',
            }"
          >
            {{ order.status.text }}
          </div>
        </div>
      </div>
      <div class="top--part">
        <div class="name mr-3">
          {{ $t('order.purchase') }}
        </div>
        <div class="date mr-3">
          {{ $date.format(order.created_at, 'dd MMMM yyyy') }}
        </div>
        <div class="status mr-2" v-if="order.status">
          <div class="label--container">
            <div
              class="label"
              :class="{
                'label--ongoing': order.status.type === 'ON_GOING',
                'label--canceled':
                  order.status.type === 'CANCELED' || order.status.type === 'EXPIRED',
              }"
            >
              {{ order.status.text }}
            </div>
          </div>
        </div>
        <div class="order--number mr-4">
          {{ order.order_number }}
        </div>
      </div>
      <div v-if="order.items">
        <div
          class="middle--part"
          v-for="(product, index) in order.items"
          :key="`order-product-${index}`"
        >
          <div class="name mb-1">
            <span class="mr-2">{{ product.name }}</span>
            <img :src="product.membership_logo" v-if="product.membership_logo" />
          </div>
          <div class="active--date" v-if="product.active_at">
            {{ $t('product.activeAt') }}: {{ product.active_at }}
          </div>
          <product-labels :benefits="product.benefits"></product-labels>
          <div class="payment--method" v-if="order.payment_method">
            <span class="font--semibold">{{ `${$t('order.paymentMethod')}: ` }}</span>
            <span>{{ `${order.payment_method}` }}</span>
          </div>
        </div>
      </div>
      <div
        class="bottom--part"
        v-if="order.allowed_actions && Array.isArray(order.allowed_actions)"
      >
        <button
          class="btn btn-primary--outline ml-2"
          @click="cancel"
          v-if="order.allowed_actions.includes('CANCEL')"
        >
          {{ $t('order.btn.cancel') }}
        </button>
        <button
          class="btn btn-primary ml-2"
          @click="openHowToPay"
          v-if="order.allowed_actions.includes('HOW_TO_PAY')"
        >
          {{ $t('order.btn.howToPay') }}
        </button>
      </div>
    </div>
    <div class="middle--side"></div>
    <div class="right--side d-flex align-items-center">
      <div class="w-100">
        <div class="payment--method payment--method-mobile" v-if="order.payment_method">
          <div class="font--semibold">{{ `${$t('order.paymentMethod')}: ` }}</div>
          <div>{{ `${order.payment_method}` }}</div>
        </div>
        <div class="open--invoice" @click="openInvoice">
          {{ $t('order.btn.invoice') }}
        </div>
        <div>
          <countdown
            class="payment--due"
            :paymentDueDiff="paymentDueDiff"
            v-if="paymentDueDiff"
          ></countdown>
          <div class="price--container">
            <div class="price--title">
              {{ $t('general.totalPrice') }}
            </div>
            <div class="price">
              {{ $n(order.price, 'currency', 'id-ID') }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HelperMixin from '@/mixins/helpers';
import ProductLabels from '@/components/utils/product-labels';
import Countdown from '@/components/order/partials/countdown';

export default {
  mixins: [HelperMixin],
  components: { ProductLabels, Countdown },
  props: {
    order: {
      require: true,
    },
  },
  computed: {
    paymentDueDiff() {
      if (this.order && this.order.payment_due) {
        return new Date(this.order.payment_due) - new Date();
      }

      return null;
    },
  },
  methods: {
    async cancel() {
      const result = await this.$swal({
        title: this.$i18n.t('order.modal.cancel.title'),
        text: this.$i18n.t('order.modal.cancel.message'),
        type: 'error',
        showCancelButton: true,
        confirmButtonText: this.$i18n.t('general.yes'),
        cancelButtonText: this.$i18n.t('general.no'),
        allowOutsideClick: false,
      });
      if (result.value && this.order) {
        try {
          await this.$store.dispatch('order/cancelOrder', this.order.uuid);
          await this.$swal(this.$t('general.success'), this.$t('order.cancelSuccess'), 'success');
          await this.$store.dispatch('order/getAllOrders');
        } catch (e) {
          console.log('CANCEL ORDER ERROR: ', e);
        }
      }
    },
    openHowToPay() {
      if (this.order) {
        this.$router.push(`/how-to-pay/${this.order.uuid}`);
      }
    },
    openInvoice() {
      if (this.order) {
        this.$router.push(`/invoice/${this.order.uuid}`);
      }
    },
  },
};
</script>
